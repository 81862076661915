import {
  IoIosMan,
  IoIosPeople,
  IoMdLocate,
  IoMdSchool,
  IoMdFlag,
  IoMdAlbums,
  IoIosHourglass,
  IoMdBusiness,
  IoMdReorder,
  IoIosCalendar,
  IoMdPersonAdd,
  IoIosMail,
  IoMdFingerPrint,
  IoMdMap,
  IoIosAlbums
} from "react-icons/io";

import { BsCalendarDate } from "react-icons/bs";

import { GiWorld } from "react-icons/gi";


export const version = "06.12.1"

export const BACKEND_URL_PREFIX = process.env.REACT_APP_URL
export const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN

export const newsOnPage = 10;

export const yunarmiansOnPage = 15;
export const yunarmiansOnPageList = 100;

export const items = [
  "РЕГИОНАЛЬНЫЙ БЛОК",
  "публикации",
  "мероприятия",
  "методический комплекс",
  "документы",
  "дома юнармии",
  "видеоконференция",
  "отчетность",
  "настройки"    
];

export const itemsItems = [
  [
    {name: "Реестр региональных отделений", value: "/reg_departments"},
    {name: "Реестр местных отделений", value: "/loc_departments"},
    {name: "Реестр юнармейских отрядов", value: "/detachments"},
    {name: "реестр юнармейцев", value: "/unarmiyans_list"},
    {name: "региональный рейтинг", value: "/reg_rating"},
    {name: "региональный рейтинг (общий)", value: "/reg_rating_general", access: "superusers" },
    {name: "познавательный туризм", value: "/tourism"},
    {name: "полигоны", children: [
      {name: "2023", sub: true, link: "/polygons.pdf"},
      {name: "2024", sub: true, link: "/polygons2024.pdf"}
    ]}
  ],
  [
    {name: "новости", value: "/news"},
    {name: "видео", value: "/videos"},
    {name: "объявления", value: "/ads"},
    {name: "экипировка", value: "/equipment"},
    {name: "цифровая академия юнармейца", value: "/digital_academy"},
    {name: "Личные события юнармейцев", value: "/stream"},
    {name: "Верни Герою имя", value: "/hero"},
  ],
  [
    {name: "мероприятия", value: "/event"},
    {name: "график мероприятий", value: "/eventsschedule"},
    {name: "календарный план основных мероприятий", value: "/chiefs_schedule"},
    {name: "задания", value: "/tasks"},
    {name: "квесты", value: "/quests"},
    {name: "план главного штаба", children: [
      {name: "2023", sub: true, link: "/ПЛАН 2023 утвержден 23.12.22.pdf"},
      {name: "2024", sub: true, link: "/ПЛАН 2024 утвержден 21.12.2023.pdf"},
    ]},
  ],
  [
    {name:  "календарь дней единых действий", value: "/test"},
    {name:  "группы методических материалов", value: "/material_groups"},   
    {name: "методические материалы", value: "/methodic_materials"},
    {name: "повышение квалификации", value: "/plug", link: "https://eduyunarmy.getcourse.ru/teach/control/stream/view/id/499411150"}
  ],
  [
    {name: "НПА", value: "/npa"},
    {name: "регламентирующие документы", value: "/reglament_docs"},
    {name: "документы главного штаба", value: "/main_docs"},
    {name: "документы слетов", value: "/meet_docs"},
    {name: "соглашения с партнерами", value: "/partner_docs"},
    {name: "соглашения с вдл", value: "/vdl_docs"},
    {name: "положения о мероприятиях", value: "/regulation"},
    {name: "приказы о мероприятиях", value: "/orders"},
    {name: "справки", value: "/references"}
  ],
  [
    {name: "реестр домов юнармии", value: "/yunarmy_houses"},
    {name: "заявки", value: "/plug"},
    {name: "обеспечение домов юнармии", value: "/plug"}    
  ],
  [

  ],
  [
    {name: "отчеты по мероприятиям гш", value: "/plug"},
    {name: "аналитическая справка", value: "/analytical_report"},
    {name: "контакты руководителей отделений", value: "/leader_contacts"},
    {name: "Рейтинг активности юнармейцев", value: "/activity_rating_report"},
    {name: "Численность по регионам", value: "/population_report"},
    {name: `Численность ВВПОД "Юнармия"`, value: "/number_rating_report"},
    {name: "региональные отчеты", value: "/plug", children: [
      {name: "региональный рейтинг", value: "/reg_rating", sub: true}
    ]},
  ],
  [
    {name: "пользователи", value: "/users"},
    {name: "роли", value: "/roles"},
    {name: "настройка прав доступа", value: "/plug"},
    {name: "настройка бейджей", value: "/badges"},
    {name: "параметры рейтинга", value: "/rating_params"},    
    {name: "награды", value: "/awards"},    
    {name: "виртуальные награды", value: "/virtual_awards"},    
    {name: "группы регионов", value: "/region_groups"},    
    {name: "регионы", value: "/regions"},    
    {name: "федеральные округа", value: "/federal_districts"},    
    {name: "журнал писем", value: "/mails_journal"},    
    {name: "сообщения", value: "/plug"},
    {name: "импорт", value: "/import"},
    {name: "о Юнармии", value: "/about"},
  ],
]

export const AUTH_ERROR_MESSAGES = {
  401: 'Ошибка отправки имени пользователя в заголовке',
  403: 'Пользователь не существует в СУО',
}

export const SCREENS = {
  '/news': 'news',
  '/event': 'event',
  '/download': 'download',
  '/settings': 'settings',
};

export const accessStatuses = ['Полный', 'Чтение', 'Нет']

export const SPORT_GRADES = ['золото', 'серебро', 'бронза', 'КМС', 'МС', '1 разряд', '2 разряд']

export const NAME_SCREENS = {
  '/event/add_event': 'Мероприятие. Добавление',
  '/event/edit_event': 'Мероприятие. Редактирование',
  '/news/add_news': 'Новость. Добавление',
  '/news/edit_news': 'Новость. Редактирование',
  '/ads/add_ads': 'Объявление. Добавление',
  '/ads/edit_ads': 'Объявление. Редактирование',
  '/add_regiondepartment': 'Региональные департаменты. Добавление',  
  '/edit_regiondepartment': 'Региональные департаменты. Редактирование',
  "/npa" : 'НПА',
  "/references" : 'Справки',
  "/reglament_docs" : 'Регламентирующие документы',
  "/main_docs" : 'Документы главного штаба',
  "/meet_docs" : 'Документы слетов',
  "/partner_docs" : 'Соглашения с партнерами',
  "/vdl_docs" : 'Соглашения с ВДЛ'  
};

export const DOC_TYPES = {
  "/npa" : 1,
  "/reglament_docs" : 2,
  "/main_docs" : 3,
  "/meet_docs" : 4,
  "/partner_docs" : 5,
  "/vdl_docs" : 6,
  "/references" : 7
}

export const filters = [
  {name: "Регион", type: "input", group: "regions", icon: <i><IoMdLocate /></i>},
  {name: "Страна", group: "country", icon: <i><GiWorld /></i>}, 
  {name: "Региональное отделение", group: "regional_department_ids", icon: <i><IoMdBusiness /></i>},
  {name: "Местное отделение", type: "input", group: "localDep", icon: <i><IoMdBusiness /></i>},
  {name: "Отряд", type: "input", group: "detachment", icon: <i><IoIosPeople /></i>},
  {name: "Населенный пункт", group: "city", icon: <i><IoMdMap /></i>},
  {name: "Школа", group: "school", icon: <i><IoMdSchool /></i>},
  {name: "ФИО", group: "lastName", icon: <i><IoMdReorder /></i>},
  {name: "Личный номер", group: "personal_id", icon: <i><IoMdFingerPrint /></i>},
  {name: "E-mail", type: "input", group: "email", icon: <i><IoIosMail /></i>},
  {name: "Пол", value: ['', 'male', 'female'], type: "radio", group: "sex", icon: <i><IoIosMan /></i>},
  {name: "Возраст", group: "age", icon: <i><IoIosHourglass /></i>},  
  {name: "Год рождения", group: "birthday_year", type: "radio", icon: <i><IoIosAlbums /></i>},  
  {name: "Тип профиля", group: "unaprofile_type", type: "radio", icon: <i><IoIosAlbums /></i>},  
  {name: "Дата создания профиля", group: "createdAt", icon: <i><IoMdPersonAdd /></i>},
  {name: "Период вступления", group: "from_date", icon: <i><IoMdAlbums /></i>},
  {name: "Дата заполнения профиля на 100%", group: "profile_filled_from_date", icon: <i><IoIosCalendar /></i>},
  {name: "Возраст на дату", group: "on_date", icon: <i><BsCalendarDate /></i>},
  {name: "Статус", value: ['', 'candidate', 'active', 'resigned', 'external_participant', 'entered_by_mistake', 'unknown', 'applicant', 'deleted'], type: "radio", group: "status", icon: <i><IoMdFlag /></i>}
]


export const filterItems = [
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  ["Все", "Мужской", "Женский"],
  [],
  [],
  [],
  [],
  [],
  [],
  [],
  ["Все", "Кандидат", "Актив", "Выбыл", "Внешний участник", "Внесен ошибочно", "Неизвестный", "Заявитель", "Удален"]
]

export const selects = {
  sex: [
    {name: "Все", value: ""},
    {name: "Мужской", value: "male"},
    {name: "Женский", value: "female"}
  ],
  status: [
    {name: "Все", value: ""},
    {name: "Кандидат", value: "candidate"},
    {name: "Актив", value: "active"},
    {name: "Выбыл", value: "resigned"},
    {name: "Внешний участник", value: "external_participant"},
    {name: "Внесен ошибочно", value: "entered_by_mistake"},
    {name: "Неизвестный", value: "unknown"},
    {name: "Заявитель", value: "applicant"},
    {name: "Удален", value: "deleted"}
  ],
  GTO: [
    {name: "Золото", value: "gold"},
    {name: "Серебро", value: "silver"},
    {name: "Бронза", value: "bronze"}
  ],
  level: [
    {name: "I", value: "I"},
    {name: "II", value: "II"},
    {name: "III", value: "III"},
    {name: "IV", value: "IV"},
    {name: "V", value: "V"},
    {name: "VI", value: "VI"}
  ],
  age: [
    {id: 1, name: "8 лет", value: "8"},
    {id: 2, name: "9 лет", value: "9"},
    {id: 3, name: "10 лет", value: "10"},
    {id: 4, name: "11 лет", value: "11"},
    {id: 5, name: "12 лет", value: "12"},
    {id: 6, name: "13 лет", value: "13"},
    {id: 7, name: "14 лет", value: "14"},
    {id: 8, name: "15 лет", value: "15"},
    {id: 9, name: "16 лет", value: "16"},
    {id: 10, name: "17 лет", value: "17"},
    {id: 11, name: "18 лет", value: "18"},
  ],
  bloodType: [
    {name: "O(I) Rh- (Первая отрицательная)", value: "1-"},
    {name: "O(I) Rh+ (Первая положительная)", value: "1+"},
    {name: "A(II) Rh- (Вторая отрицательная)", value: "2-"},
    {name: "A(II) Rh+ (Вторая положительная)", value: "2+"},
    {name: "B(III) Rh- (Третья отрицательная)", value: "3-"},
    {name: "B(III) Rh+ (Третья положительная)", value: "3+"},
    {name: "AB(IV) Rh- (Четвертая отрицательная)", value: "4-"},
    {name: "AB(IV) Rh+ (Четвертая положительная)", value: "4+"}
  ],
  healthGroup: [
    {name: "I группа здоровья", value: "1"},
    {name: "II группа здоровья", value: "2"},
    {name: "III группа здоровья", value: "3"},
    {name: "IV группа здоровья", value: "4"},
    {name: "V группа здоровья", value: "5"}
  ],
  education: [
    {name: "Основное общее образование", value: "basic"},
    {name: "Среднее общее образование", value: "general"},
    {name: "Среднее профессиональное образование", value: "vocational"},
    {name: "Высшее образование — бакалавриат", value: "bachelorship"},
    {name: "Высшее образование — специалитет", value: "speciality"},
    {name: "Высшее образование — магистратура", value: "magistracy"},
    {name: "Высшее образование — подготовка кадров высшей квалификации", value: "highly qualified personnel"}
  ]
}

export const eventLevels = [
  {value: 1, name: "Федеральный"},
  {value: 2, name: "Региональный"},
  {value: 3, name: "Местный"},
  {value: 4, name: "Окружной"}
]

export const available = [
  {value: '', name: "Выбрать"},
  {value: 'all', name: "Всем"},
  {value: 'agsh', name: "Сотрудникам АГШ"},
  {value: 'nrsh', name: "Начальникам РШ"},
]

export const itemsCount = [10,15,20,25,30]

export const monthArray = [
  { name: 'Январь', value: 1 },
  { name: 'Февраль', value: 2 },
  { name: 'Март', value: 3 },
  { name: 'Апрель', value: 4 },
  { name: 'Май', value: 5 },
  { name: 'Июнь', value: 6 },
  { name: 'Июль', value: 7 },
  { name: 'Август', value: 8 },
  { name: 'Сентябрь', value: 9 },
  { name: 'Октябрь', value: 10 },
  { name: 'Ноябрь', value: 11 },
  { name: 'Декабрь', value: 12 },
]

export const paramsEditUser = [
  {name: 'АГШ', value: 'agsh' },
  {name: 'НРШ', value: 'nrsh' },
  {name: 'SuperUser', value: 'superuser' },
  {name: 'Никто', value: 'nobody' }
]

export const optionsStap = [
  { value: 0, name: "Нет" },
  { value: 1, name: "Фото" },
  { value: 2, name: "Ссылка" },
  { value: 3, name: "Файл" }
];

export const confirmParamTypes = [
  {name: "Строка", value: 1},
  {name: "Ссылка", value: 2},
  {name: "Файл", value: 3},
  {name: "Дата", value: 4},
  {name: "Целое", value: 5},
  {name: "Внутренняя ссылка", value: 6}
]

export const directories = [
  {name: "Реестр местных отделений", link: "/loc_departments"},
  {name: "Реестр юнармейских отрядов", link: "/detachments"},
  {name: "Реестр юнармейцев", link: "/unarmiyans_list"},
  {name: "Реестр домов Юнармии", link: "/yunarmy_houses"},
  {name: "Методические материалы", link: "/methodic_materials"},
  {name: "Пользователи", link: "/users"}
]

export const errorFieldsList = {
  lastName: 'Фамилия',
  firstName: 'Имя',
  fathersName: 'Отчество',
  regionId: 'Регион',
  birthday: 'День рождения',
  sex: 'Пол',
  email: 'E-mail',
  phone: 'Контактный телефон',
  personalId: 'Личный номер',
  status: 'Текущий статус',
  postalCode: 'Индекс',
  aboutSelf: 'О себе',
  city: 'Населенный пункт',
  address: 'Адрес',
  joiningReason: 'Причина вступления в ЮНАРМИЮ',
  motherLastName: 'Фамилия матери',
  motherFirstName: 'Имя матери',
  motherFathersName: 'Отчество матери',
  fatherLastName: 'Фамилия отца',
  fatherFirstName: 'Имя отца',
  fatherFathersName: 'Отчество отца',
  guardianLastName: 'Фамилия опекуна',
  guardianFirstName: 'Имя опекуна',
  guardianFathersName: 'Отчество опекуна',
  school: 'Название образовательной организации',
  class: 'Класс',
  sportSectionsName: 'Название спортивной секции',
  sport_section_result: 'Краткое резюме достижений',
  motherContactPhone: 'Контактный номер матери',
  fatherContactPhone: 'Контактный номер отца',
  guardianContactPhone: 'Контактный номер опекуна',
  motherEmail: 'E-mail матери',
  fatherEmail: 'E-mail отца',
  guardianEmail: 'E-mail опекуна'
}

export const textPlugs = ['Фамилия Имя Отчество', 'Регион', 'Дата', 'Мероприятие', 'Местное отделение', 'Отряд']

export const requestStatuses = [
  {name: 'Рассмотрение', value: 1},
  {name: 'Участие', value: 2},
  {name: 'Отказ', value: 3}
]

export const profile_types = [
  {name: 'Сотрудник АГШ', value: 'Сотрудник АГШ'},
  {name: 'Сотрудник РШ', value: 'Сотрудник РШ'},
  {name: 'Сотрудник МО', value: 'Сотрудник МО'},
  {name: 'Руководитель отряда', value: 'Руководитель отряда'},
  {name: 'Юнармеец', value: 'Юнармеец'},
]