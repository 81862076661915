import React, {useState} from 'react'
import styles from './index.module.css';
import { createNotification } from '../../../components/Notifications/Notifications';

const Index = ({
    endpoint = '',
    token,
    getUpdate = () => {}
}) => {
    const [file,
        setFile] = useState(null);
    const [progress,
        setProgress] = useState(0);

    const chunkSize = 1024 * 1024; // Размер чанка (1 МБ)

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setProgress(0);
    };

    const uploadChunk = async(chunk, chunkIndex, totalChunks, qqtotalfilesize, chunkSize, partbyteoffset) => {
        const formData = new FormData();
        formData.append("qqfile", chunk);
        formData.append("qqpartindex", chunkIndex);
        formData.append("qqtotalparts", totalChunks);
        formData.append("qqfilename", file.name);
        formData.append("qquuid", "e712180f-4f81-4b9f-81b2-4da4f8972119");
        formData.append("qqtotalfilesize", qqtotalfilesize);
        formData.append("qqchunksize", chunkSize);
        formData.append("qqpartbyteoffset", partbyteoffset);

        return fetch(endpoint, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Basic ${btoa(`nnz:${token}`)}`,
                "Transfer-Encoding": "chunked"
            },
            method: "POST",
            body: formData
        });
    };

    const handleUpload = async() => {
        if (!file) 
            return;
        
        const totalChunks = Math.ceil(file.size / chunkSize);
        let uploadedChunks = 0;
        let sumChunkSize = 0;
        for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);
            sumChunkSize += chunk.size

            try {
                await uploadChunk(chunk, i, totalChunks, file.size, chunk, sumChunkSize);
                uploadedChunks++;
                setProgress(Math.floor((uploadedChunks / totalChunks) * 100));
            } catch (error) {
                createNotification("error", `Ошибка загрузки`);
                break;
            } 
        }

        if (uploadedChunks === totalChunks) {
            createNotification("success", `Файл успешно загружен`);
            getUpdate();
        }

        
    };

    return (
        <div>
            <h1 className={styles.title}>Загрузка файла</h1>
            <div className={styles.fileInput}>
                <input type="file" accept="video/mp4,video/x-m4v,video/*" onChange={handleFileChange}/>
                <button 
                    className={styles.button}
                    onClick={handleUpload} 
                    disabled={!file}>
                    Загрузить
                </button>
            </div>
            {file && <p className={styles.progressText}>Прогресс загрузки: {progress}%</p>}
            <progress className={styles.progress} value={progress} max="100"></progress>
        </div>
    );
};

export default Index